import { Box, Menu, MenuItem } from '@mui/material'
import { DataGrid, GridColDef, GridRowSelectionModel, GridSlotsComponentsProps, GridToolbarContainer } from '@mui/x-data-grid'
import { useContext, useState } from 'react'
import { OpenIDContext } from '../../../../api/auth'
import { useCompanyDashboardContext } from '../../../../api/hooks'
import idBackendRequestHandler from '../../../../api/idBackendRequestHandler'
import SecDimButton from '../../../../shared/components/SecDimButton/SecDimButton'
import theme from '../../../../utils/theme'
import * as Styled from './CompanyPlayersActivitiesTableStyles'

// Required for allow custom toolbar to have custom props
// See: https://mui.com/x/react-data-grid/components/#custom-slot-props-with-typescript
declare module '@mui/x-data-grid' {
	// eslint-disable-next-line no-unused-vars
	interface ToolbarPropsOverrides {
		company: Company
		selectedRows: CompanyPlayerActivityInfoRow[];
	}
}

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'Rank',
		align: 'center',
		headerAlign: 'center',
		flex: 25,
	},
	{ field: 'playerEmail', headerName: 'Player', flex: 150 },
	{ field: 'department', headerName: 'Department', flex: 150 },
	{
		field: 'attempts',
		headerName: 'Attempts',
		type: 'number',
		align: 'center',
		headerAlign: 'center',
		flex: 75,
	},
]

function CustomToolbar({ company, selectedRows }: NonNullable<GridSlotsComponentsProps['toolbar']>) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const { profile } = useContext(OpenIDContext)

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const assignDepartment = (option: string) => {
		if (selectedRows && selectedRows.length !== 0) {
			const users: { email: string, department: string }[] = []
			selectedRows?.forEach((row) => {
				users.push({ "email": row.playerEmail, department: option })
			})

			const data = {
				username: profile.username,
				users
			};

			idBackendRequestHandler(
				'batchUserUpdate',
				data,
				undefined,
				undefined
			)
		}

		handleClose();
	}

	return (
		<GridToolbarContainer sx={{ paddingBottom: 1 }}>
			<Box sx={{ flexGrow: 1 }} />
			<SecDimButton rounded variant="outlined" sx={{
				backgroundColor: theme.palette.common.white,
				color: theme.palette.primary.main,
				'&:hover': {
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.common.white,
				}
			}} onClick={handleClick}>Assign Department</SecDimButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				sx={{
					maxHeight: 500,
					width: "auto"
				}}
			>
				{company?.departments.map((department) => (
					<MenuItem key={department.name} onClick={() => assignDepartment(department.name)}>
						{department.name}
					</MenuItem>
				))}
			</Menu>
		</GridToolbarContainer>
	);
}

function CompanyPlayersActivitiesTable({ className, company, companyPlayersActivitiesData }: CompanyPlayersActivitiesTableProps) {
	const { setOpenCompanyPlayerProfileDialogInfo } = useCompanyDashboardContext()
	const [pageSize, setPageSize] = useState(15)
	const rows: CompanyPlayerActivityInfoRow[] = []
	const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
	const [selectedRows, setSelectedRows] = useState<CompanyPlayerActivityInfoRow[]>([])

	const onClickTableRow = (rowData: any) => {
		const companyPlayerActivities = companyPlayersActivitiesData.find((data) => data.player_email === rowData.row.playerEmail)
		setOpenCompanyPlayerProfileDialogInfo({
			isDialogShown: true,
			playerEmail: companyPlayerActivities!.player_email,
			playerUsername: companyPlayerActivities!.player_username,
		})
	}

	for (let index = 0; index < companyPlayersActivitiesData.length; index += 1) {
		let rank: string
		if (index === 0) rank = '🥇'
		else if (index === 1) rank = '🥈'
		else if (index === 2) rank = '🥉'
		else rank = String(index + 1)
		rows.push({
			id: rank,
			playerEmail: companyPlayersActivitiesData[index].player_email,
			department: companyPlayersActivitiesData[index].player_department ? companyPlayersActivitiesData[index].player_department : "-",
			attempts: companyPlayersActivitiesData[index].total_challenge_attempts,
		})
	}

	const handlePageSizeChange = (params: any) => {
		setPageSize(params.pageSize)
	}

	return (
		<Styled.TableBox className={className}>
			<DataGrid
				checkboxSelection
				disableRowSelectionOnClick
				rows={rows}
				columns={columns}
				onRowCountChange={handlePageSizeChange}
				pageSizeOptions={[15, 25, 50, 100]}
				autoHeight
				rowHeight={40}
				localeText={{
					MuiTablePagination: {
						labelRowsPerPage: '',
					},
				}}
				columnHeaderHeight={40}
				pagination
				onRowClick={onClickTableRow}
				initialState={{
					pagination: {
						paginationModel: { pageSize },
					},
				}}
				slots={{
					toolbar: CustomToolbar,
				}}
				slotProps={{
					toolbar: { company, selectedRows },
				}}
				onRowSelectionModelChange={(newRowSelectionModel, details) => {
					const selectedRowsData: CompanyPlayerActivityInfoRow[] = newRowSelectionModel.map((id) => {
						const row = details.api.getRow(id);
						return row !== null ? row : []
					})
					setRowSelectionModel(newRowSelectionModel);
					setSelectedRows(selectedRowsData)
				}}
				rowSelectionModel={rowSelectionModel}
			/>
		</Styled.TableBox>
	)
}
export default CompanyPlayersActivitiesTable
